// extracted by mini-css-extract-plugin
export var completed = "Course-module--completed--034e6";
export var courseDescription = "Course-module--course-description--03f80";
export var courseHeader = "Course-module--course-header--eb5ba";
export var courseImage = "Course-module--course-image--03d35";
export var courseSubject = "Course-module--course-subject--dbb2c";
export var courseWrapper = "Course-module--course-wrapper--2ccc4";
export var coursesCarousel = "Course-module--courses-carousel--2caae";
export var coursesContainer = "Course-module--courses-container--2c72f";
export var coursesDesktop = "Course-module--courses-desktop--8d51a";
export var fav = "Course-module--fav--8d0c6";
export var grecaptchaBadge = "Course-module--grecaptcha-badge--b4525";
export var hideOnDesktop = "Course-module--hide-on-desktop--70917";
export var hideOnMobile = "Course-module--hide-on-mobile--61ec5";
export var iconAccountDefault = "Course-module--icon-account-default--c9bc6";
export var iconArrow = "Course-module--icon-arrow--b641c";
export var iconBrand = "Course-module--icon-brand--c7f1c";
export var iconBrand1 = "Course-module--icon-brand1--ed097";
export var iconBrand2 = "Course-module--icon-brand2--d3baf";
export var iconCalculator = "Course-module--icon-calculator--461cb";
export var iconCamera = "Course-module--icon-camera--95a1e";
export var iconCheckbox = "Course-module--icon-checkbox--433d4";
export var iconChevron = "Course-module--icon-chevron--bece5";
export var iconDelete = "Course-module--icon-delete--a6d72";
export var iconDisclaimer = "Course-module--icon-disclaimer--8e345";
export var iconDone = "Course-module--icon-done--4a7b3";
export var iconDownload = "Course-module--icon-download--9b11e";
export var iconEdit = "Course-module--icon-edit--84e30";
export var iconFacebook = "Course-module--icon-facebook--09d91";
export var iconFaq = "Course-module--icon-faq--41015";
export var iconFaqUp = "Course-module--icon-faq-up--e5236";
export var iconFilledHeart = "Course-module--icon-filled-heart--930d3";
export var iconFlag = "Course-module--icon-flag--96fc7";
export var iconHeart = "Course-module--icon-heart--15d39";
export var iconInstagram = "Course-module--icon-instagram--11e7f";
export var iconLog = "Course-module--icon-log--b7d2a";
export var iconLoginArrow = "Course-module--icon-login-arrow--09795";
export var iconLogout = "Course-module--icon-logout--9d5ed";
export var iconPassword = "Course-module--icon-password--1671c";
export var iconPinterestP = "Course-module--icon-pinterest-p--ce176";
export var iconRate15 = "Course-module--icon-rate-15--32b4b";
export var iconRate25 = "Course-module--icon-rate-25--75fee";
export var iconRate35 = "Course-module--icon-rate-35--12fac";
export var iconRate45 = "Course-module--icon-rate-45--617b5";
export var iconRate55 = "Course-module--icon-rate-55--61044";
export var iconRating = "Course-module--icon-rating--dee94";
export var iconSocial = "Course-module--icon-social--76798";
export var iconSocial1 = "Course-module--icon-social1--77506";
export var iconSocial2 = "Course-module--icon-social2--071b0";
export var iconStores = "Course-module--icon-stores--c2d26";
export var iconTrophy = "Course-module--icon-trophy--e804c";
export var iconUser = "Course-module--icon-user--7c04c";
export var iconUserCircle = "Course-module--icon-user-circle--ebadc";
export var iconYoutube = "Course-module--icon-youtube--81da8";
export var pageWidth = "Course-module--page-width--efe37";
export var progressBar = "Course-module--progress-bar--60d43";
export var progressBarFill = "Course-module--progress-bar-fill--193ef";
export var progressBarWrapper = "Course-module--progress-bar-wrapper--e3604";
export var ratingBox = "Course-module--rating-box--64d55";
export var stepCompleted = "Course-module--step-completed--29e1f";
export var steps = "Course-module--steps--9b046";
export var subjectInfo = "Course-module--subject-info--a369b";
export var totalSteps = "Course-module--total-steps--cf21f";
export var view = "Course-module--view--b4259";
export var views = "Course-module--views--9a1f8";