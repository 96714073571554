import React from 'react'

import cn from 'classnames'

import * as st from '~/assets/styl/Showcase.module.styl'

import { useContext } from '~/hooks'

import { graphql, useStaticQuery } from 'gatsby'
import { useApi } from '~/siteApi'
import Slider from '@fil1pe/react-slider'
import { useMediaQuery } from 'react-responsive'
import Link from './Link'

export default function Showcase() {
  const { profile } = useContext()
  const isLogged = Boolean(profile)
  const isDesktop = useMediaQuery({ query: '(min-width: 1081px)' })

  const { result: showcases } = useApi(
    {
      result: useStaticQuery(graphql`
        query {
          allShowcases {
            nodes {
              external
              id
              image
              mobile
              name
              url
              title
              description
              button
            }
          }
        }
      `).allShowcases.nodes,
    },
    'showcases'
  )

  return (
    <div className={cn(st.showcaseContainer, 'page-width')}>
      {!isDesktop && (
        <div className={st.showcaseHeader}>
          {isLogged ? (
            <>
              Olá <span>{profile.name.split(' ')[0]}</span>, <br />
              continue seus estudos.
            </>
          ) : (
            <>
              Seja Bem-Vindo, <br />
              ao Guia Pointer.
            </>
          )}
        </div>
      )}
      <div className={st.imageContainer}>
        <Slider
          slidesToShow={1}
          slidesToScroll={1}
          autoplayTimeout={10000}
          renderArrow={(props) => <button {...props}></button>}
        >
          {showcases.map((showcase) => (
            <div
              className={cn(st.bg, {
                [st.overlay]: showcase.title || showcase.description,
              })}
              key={showcase.id}
              style={{
                backgroundImage: `url(${
                  isDesktop ? showcase.image : showcase.mobile
                })`,
              }}
            >
              {/* {isLogged && (
                <div className={st.ratingBox}>
                  <span className="icon-rating"></span>
                  4,8
                </div>
              )} */}
              {isDesktop ? (
                <>
                  <div className={st.desktopText}>
                    <div className={st.desktopTextUpper}>
                      {showcase.title && <>{showcase.title}</>}
                    </div>
                    {showcase.description && (
                      <span className={st.desktopTitle}>
                        <b>{showcase.description}</b>
                      </span>
                    )}
                    {showcase.button && (
                      <Link
                        href={showcase.url}
                        target={showcase.external && '_blank'}
                        rel={
                          showcase.external && 'noreferrer external nofollow'
                        }
                      >
                        <span className="icon-flag"></span>
                        {showcase.button}
                      </Link>
                    )}
                  </div>
                </>
              ) : (
                <div className={st.textWrapper}>
                  {showcase.description && (
                    <span className={st.title}>
                      <b>{showcase.description}</b>
                    </span>
                  )}
                  {showcase.button && (
                    <Link
                      href={showcase.url}
                      target={showcase.external && '_blank'}
                      rel={showcase.external && 'noreferrer external nofollow'}
                    >
                      <span className="icon-flag"></span>
                      {showcase.button}
                    </Link>
                  )}
                </div>
              )}
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}
