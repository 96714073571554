import React from 'react'
import { Head } from '~/components'
import Course from '~/components/Course'
import Newsletter from '~/components/Newsletter'
import Showcase from '~/components/Showcase'

const Home = () => {
  return (
    <>
      <Head />
      <Showcase />
      <Course />
      <Newsletter />
    </>
  )
}

export default Home
