/* eslint-disable prettier/prettier */
import React, { useEffect, useState } from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import Slider from '@fil1pe/react-slider'

import cn from 'classnames'
import { useMediaQuery } from 'react-responsive'

import * as st from '~/assets/styl/Course.module.styl'
import { useContext } from '~/hooks'

import api from '~/siteApi'

export default function Course() {
  const { profile, token, setProfile } = useContext()
  const isLogged = Boolean(profile)
  // const steps = [1, 2, 3, 4, 5]
  const isNotebook = useMediaQuery({ query: '(min-width: 1081px)' })
  const isDesktop = useMediaQuery({ query: '(min-width: 1501px)' })

  const [courses, setCourses] = useState([])
  useEffect(() => {
    api('get', 'courses').then(({ result: courses }) => {
      const promises = []
      const studies = {}
      for (const course of courses)
        promises.push(
          api('get', 'courses/' + course.slug).then((res) => {
            studies[course.slug] = res.studies
          })
        )
      Promise.all(promises).then(() => {
        setCourses(
          courses.map((course) => ({
            ...course,
            studies: studies[course.slug],
          }))
        )
      })
    })
  }, [])

  const favs = (profile?.studies_favorites || []).map((fav) => fav.study)
  const prog = (profile?.progress || []).map((progs) => progs.study)

  function favorite(e, favorite) {
    e.preventDefault()
    const { studies_favorites } = profile
    api('patch', `studies/${favorite.id}/favorite`, null, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(() => {
        studies_favorites.push({ study: favorite.id })
        setProfile(profile)
      })
      .catch(([errors]) => {
        console.error(errors)
      })
  }

  function disfavor(e, disfavor) {
    e.preventDefault()
    const { studies_favorites } = profile
    api('patch', `studies/${disfavor.id}/disfavor`, null, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(() => {
        studies_favorites.splice(
          studies_favorites.findIndex(({ study }) => study === disfavor.id),
          1
        )
        setProfile(profile)
      })
      .catch(([errors]) => {
        console.error(errors)
      })
  }

  return (
    <div className={cn(st.coursesContainer, 'page-width')}>
      {courses.map((item, index) => (
        <div key={index} className={st.courseWrapper}>
          <section className={st.courseHeader}>
            <h2>{item.name}</h2>
            <AnchorLink to={'/curso/' + item.slug}>
              Ver todos
              <span className="icon-chevron"></span>
            </AnchorLink>
          </section>
          <div className={st.courseDescription}>{item.description}</div>
          {!isNotebook ? (
            <Slider
              slidesToShow={2}
              slidesToScroll={1}
              finite={true}
              className={st.coursesCarousel}
              renderArrow={(props) => <button {...props}></button>}
            >
              {item.studies.map((study, id) => (
                <AnchorLink key={id} to={'/modulo/' + study.slug}>
                  <div className={st.courseSubject}>
                    <div
                      className={st.courseImage}
                      style={{ backgroundImage: `url(${study.image})` }}
                    >
                      {isLogged && (
                        <>
                          <div className={st.fav}>
                            {favs.includes(study.id) ? (
                              <span
                                onClick={(e) => disfavor(e, study)}
                                className="icon-filled-heart"
                              ></span>
                            ) : (
                              <span
                                onClick={(e) => favorite(e, study)}
                                className="icon-heart"
                              ></span>
                            )}
                          </div>
                          {study.rating > 0 && (
                            <div className={st.ratingBox}>
                              <span className="icon-rating"></span>
                              {study.rating.toString().replace('.', ',')}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                    <section className={st.subjectInfo}>
                      <h3>
                        <b>{study.name}</b>
                      </h3>
                      <p>{study.description}</p>
                    </section>
                    {isLogged && (
                      <div className={st.progressBar}>
                        <div className={st.progressBarWrapper}>
                          <div
                            className={st.progressBarFill}
                            style={{
                              width: `${
                                profile.progress.find(({ study: id}) => study.id === id)?.progress || 0
                              }%`,
                            }}
                          ></div>
                        </div>
                        <p>
                          <span>
                            {(profile.progress.find(({ study: id}) => study.id === id)?.progress || 0)}%
                          </span>
                          concluído
                        </p>
                      </div>
                    )}
                    {/* <div className={st.steps}>
                    <ul
                      style={{
                        gridTemplateColumns: `repeat(${subject.total},1fr)`,
                      }}
                    >
                      {steps.map((step, id) => (
                        <li
                          className={cn({
                            [st.stepCompleted]: id + 1 <= subject.completed,
                          })}
                          key={id}
                        ></li>
                      ))}
                    </ul>
                    <div>
                      <span className={st.completed}>{subject.completed}</span>
                      <span className={st.totalSteps}>
                        /<b>5</b>
                      </span>
                    </div>
                  </div> */}
                  </div>
                </AnchorLink>
              ))}
            </Slider>
          ) : (
            <div className={st.coursesDesktop}>
              {item.studies.slice(0, (isDesktop ? 4 : 3)).map((study, id) => (
                <AnchorLink key={id} to={'/modulo/' + study.slug}>
                  <div className={st.courseSubject}>
                    <div
                      className={st.courseImage}
                      style={{ backgroundImage: `url(${study.image})` }}
                    >
                      {isLogged && (
                        <>
                          <div className={st.fav}>
                            {favs.includes(study.id) ? (
                              <span
                                onClick={(e) => disfavor(e, study)}
                                className="icon-filled-heart"
                              ></span>
                            ) : (
                              <span
                                onClick={(e) => favorite(e, study)}
                                className="icon-heart"
                              ></span>
                            )}
                          </div>
                          {study.rating > 0 && (
                            <div className={st.ratingBox}>
                              <span className="icon-rating"></span>
                              {study.rating.toString().replace('.', ',')}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                    <section className={st.subjectInfo}>
                      <h3>
                        <b>{study.name}</b>
                      </h3>
                      <p>{study.description}</p>
                    </section>
                    {isLogged && (
                      <div className={st.progressBar}>
                        <div className={st.progressBarWrapper}>
                          <div
                            className={st.progressBarFill}
                            style={{
                              width: `${
                                profile.progress.find(({ study: id}) => study.id === id)?.progress || 0
                              }%`
                              ,
                            }}
                          >
                          </div>
                        </div>
                        <p>
                          <span>{profile.progress.find(({ study: id}) => study.id === id)?.progress || 0}%</span>
                          concluído
                        </p>
                      </div>
                    )}
                    {/* <div className={st.steps}>
                    <ul
                      style={{
                        gridTemplateColumns: `repeat(${subject.total},1fr)`,
                      }}
                    >
                      {steps.map((step, id) => (
                        <li
                          className={cn({
                            [st.stepCompleted]: id + 1 <= subject.completed,
                          })}
                          key={id}
                        ></li>
                      ))}
                    </ul>
                    <div>
                      <span className={st.completed}>{subject.completed}</span>
                      <span className={st.totalSteps}>
                        /<b>5</b>
                      </span>
                    </div>
                  </div> */}
                  </div>
                </AnchorLink>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  )
}
